import React, { createContext, useContext, useState, useEffect } from "react";
import { UniV3 } from "../types/UniV3";
import axios from "axios";
import {
  nonFungiblePositionManager,
  poolAddress,
  staker,
  subgraphEndpoint,
  subgraphId,
} from "../config/constants";
import { useAccount, useClient } from "wagmi";
import { getContract } from "viem";
import { uniswapV3StakerAbi } from "../config/abis/UniswapV3Staker";
import { nonFungiblePositionManagerAbi } from "../config/abis/NonFungiblePositionManager";

interface LpsContextType {
  stakedLPs: UniV3[];
  availableLPs: UniV3[];
  setStakedLPs: (stakedLPs: UniV3[]) => void;
  setAvailableLPs: (availableLPs: UniV3[]) => void;
  removeStakedLP: (lp: UniV3) => void;
  removeAvailableLP: (lp: UniV3) => void;
  addStakedLP: (lp: UniV3) => void;
  addAvailableLP: (lp: UniV3) => void;
}

const LpsContext = createContext<LpsContextType>({
  stakedLPs: [],
  availableLPs: [],
  setStakedLPs: () => {},
  setAvailableLPs: () => {},
  removeStakedLP: () => {},
  removeAvailableLP: () => {},
  addStakedLP: () => {},
  addAvailableLP: () => {},
});

interface LpsProviderProps {
  children: React.ReactNode;
}

const LpsProvider: React.FC<LpsProviderProps> = ({ children }) => {
  const [stakedLPs, setStakedLPsInternal] = useState<UniV3[]>([]);
  const [availableLPs, setAvailableLPsInternal] = useState<UniV3[]>([]);

  function setStakedLPs(stakedLP: UniV3[]) {
    if (account?.address) {
      setStakedLPsInternal(stakedLP);
      localStorage.setItem(
        `lastUpdated_${account.address}`,
        Date.now().toString(),
      );
      localStorage.setItem(
        `stakedLPs_${account.address}`,
        JSON.stringify(stakedLP),
      );
    }
  }

  function setAvailableLPs(availableLP: UniV3[]) {
    if (account?.address) {
      setAvailableLPsInternal(availableLP);
      localStorage.setItem(
        `lastUpdated_${account.address}`,
        Date.now().toString(),
      );
      localStorage.setItem(
        `availableLPs_${account.address}`,
        JSON.stringify(availableLP),
      );
    }
  }

  function removeStakedLP(lp: UniV3) {
    if (account?.address) {
      setStakedLPsInternal((prev) => prev.filter((l) => l.id !== lp.id));
      localStorage.setItem(
        `lastUpdated_${account.address}`,
        Date.now().toString(),
      );
      localStorage.setItem(
        `stakedLPs_${account.address}`,
        JSON.stringify(stakedLPs),
      );
    }
  }

  function removeAvailableLP(lp: UniV3) {
    if (account?.address) {
      setAvailableLPsInternal((prev) => prev.filter((l) => l.id !== lp.id));
      localStorage.setItem(
        `lastUpdated_${account.address}`,
        Date.now().toString(),
      );
      localStorage.setItem(
        `availableLPs_${account.address}`,
        JSON.stringify(availableLPs),
      );
    }
  }

  function addStakedLP(lp: UniV3) {
    if (account?.address) {
      setStakedLPsInternal((prev) => [...prev, lp]);
      localStorage.setItem(
        `lastUpdated_${account.address}`,
        Date.now().toString(),
      );
      localStorage.setItem(
        `stakedLPs_${account.address}`,
        JSON.stringify(stakedLPs),
      );
    }
  }

  function addAvailableLP(lp: UniV3) {
    if (account?.address) {
      setAvailableLPsInternal((prev) => [...prev, lp]);
      localStorage.setItem(
        `lastUpdated_${account.address}`,
        Date.now().toString(),
      );
      localStorage.setItem(
        `availableLPs_${account.address}`,
        JSON.stringify(availableLPs),
      );
    }
  }

  const account = useAccount();
  const publicClient = useClient();

  useEffect(() => {
    const filteredStakedLPs = async (
      stakedLP: UniV3[],
      accountAddress: string,
    ): Promise<UniV3[]> => {
      if (!publicClient) return [];
      const contract = getContract({
        address: staker,
        abi: uniswapV3StakerAbi,
        client: publicClient,
      });
      const stakedLPsFiltered: UniV3[] = await Promise.all(
        stakedLP.map(async (lp) => {
          const owner = await contract.read.ownerOf([BigInt(lp.id)]);
          return owner === accountAddress ? lp : null;
        }),
      ).then((results) => results.filter((lp) => lp !== null));

      return stakedLPsFiltered;
    };

    const fetchTokenUris = async (lps: UniV3[]): Promise<UniV3[]> => {
      if (!publicClient) return [];
      const contract = getContract({
        address: nonFungiblePositionManager,
        abi: nonFungiblePositionManagerAbi,
        client: publicClient,
      });
      const tokenUris: UniV3[] = await Promise.all(
        lps.map(async (lp) => {
          const tokenUri = await contract.read.tokenURI([BigInt(lp.id)]);
          return {
            ...lp,
            tokenUri: JSON.parse(atob(tokenUri.split(",")[1])).image,
          };
        }),
      );

      return tokenUris;
    };

    const fetchData = async (accountAddress: string) => {
      try {
        const data = await axios.post(
          `${subgraphEndpoint}${process.env.REACT_APP_THEGRAPH_API_KEY}/subgraphs/id/${subgraphId}`,
          {
            query: `{
              positions( where: {
                owner_in: ["${accountAddress}", "${staker}"],
                pool_: {id: "${poolAddress}"}
              }) {
                id
                owner
              }
            }`,
          },
        );
        // available lps are the one with owner as the account address
        const availableLP: UniV3[] = data.data.data.positions
          .filter(
            (position: any) =>
              position.owner.toLowerCase() === accountAddress.toLowerCase(),
          )
          .map((position: any) => ({
            id: position.id,
          }));
        setAvailableLPs(availableLP);

        // staked lps are the one with owner as the staker address
        const stakedLP: UniV3[] = data.data.data.positions
          .filter(
            (position: any) =>
              position.owner.toLowerCase() === staker.toLowerCase(),
          )
          .map((position: any) => ({
            id: position.id,
          }));
        const stakedLPsFiltered = await filteredStakedLPs(
          stakedLP,
          accountAddress,
        );
        setStakedLPs(stakedLPsFiltered);

        const [availableLPsTokenUris, stakedLPsTokenUris] = await Promise.all([
          fetchTokenUris(availableLP),
          fetchTokenUris(stakedLPsFiltered),
        ]);
        setAvailableLPs(availableLPsTokenUris);
        setStakedLPs(stakedLPsTokenUris);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (!account || !account.address) {
      return;
    }
    const storedStakedLPs = localStorage.getItem(
      `stakedLPs_${account.address}`,
    );
    const storedAvailableLPs = localStorage.getItem(
      `availableLPs_${account.address}`,
    );
    const lastUpdated = localStorage.getItem(`lastUpdated_${account.address}`);

    if (lastUpdated && Date.now() - parseInt(lastUpdated) < 5 * 60 * 1000) {
      if (storedStakedLPs) {
        setStakedLPs(JSON.parse(storedStakedLPs) as UniV3[]);
      }

      if (storedAvailableLPs) {
        setAvailableLPs(JSON.parse(storedAvailableLPs) as UniV3[]);
      }
    } else {
      fetchData(account.address);
    }
  }, [account?.address]);

  return (
    <LpsContext.Provider
      value={{
        stakedLPs,
        availableLPs,
        setStakedLPs,
        setAvailableLPs,
        removeStakedLP,
        removeAvailableLP,
        addStakedLP,
        addAvailableLP,
      }}
    >
      {children}
    </LpsContext.Provider>
  );
};

const useLps = () => {
  return useContext(LpsContext);
};

export { LpsProvider, useLps };
