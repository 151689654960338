import { Box, Text, Icon, VStack, HStack } from "@chakra-ui/react";
import { FaTwitter, FaHeart } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <Box
      mt={"40px"}
      as="footer"
      py={4}
      borderTop="1px solid"
      borderColor="gray.900"
      width="100%"
      bg={"bg"}
    >
      <VStack>
        <HStack gap={0} align="center">
          <Link
            to="https://x.com/PrimeMaxi_fi"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon fontSize="2xl" color="gray.300">
              <span>
                <FaTwitter />
              </span>
            </Icon>
          </Link>
          <Link
            to="https://x.com/PrimeMaxi_fi"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Text fontSize="lg" color="gray.300" ml={2}>
              @PrimeMaxi_fi
            </Text>
          </Link>
        </HStack>
        <Text fontSize="sm" color="gray.500" mt={2}>
          made with{" "}
          <Icon color="gray.500">
            <span>
              <FaHeart />
            </span>
          </Icon>{" "}
          by 0xtekgrinder
        </Text>
      </VStack>
    </Box>
  );
};

export default Footer;
