export function convertSeconds(seconds: number) {
  const days = Math.floor(seconds / 86400);
  seconds %= 86400;
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);
  const secs = (seconds % 60).toFixed(0);

  return { days, hours, minutes, seconds: secs };
}
