import { nonFungiblePositionManager, staker } from "../config/constants";
import { Button, Card, Text, Spinner, Box } from "@chakra-ui/react";
import { useAccount, useWriteContract } from "wagmi";
import { nonFungiblePositionManagerAbi } from "../config/abis/NonFungiblePositionManager";
import { UniV3 } from "../types/UniV3";
import { useLps } from "../providers/LpsProvider";
import { uniswapV3StakerAbi } from "../config/abis/UniswapV3Staker";

const LPCard = ({ lp, stake }: { lp: UniV3; stake: boolean }) => {
  const { writeContract, isSuccess } = useWriteContract();
  const account = useAccount();

  const { addAvailableLP, addStakedLP, removeAvailableLP, removeStakedLP } =
    useLps();

  function depositOrBurn() {
    if (!account?.address) return;

    if (stake) {
      writeContract({
        abi: nonFungiblePositionManagerAbi,
        address: nonFungiblePositionManager,
        functionName: "safeTransferFrom",
        args: [account.address, staker, BigInt(lp.id)],
      });

      // If success remove from available and add to staked
      if (isSuccess) {
        removeAvailableLP(lp);
        addStakedLP(lp);
      }
    } else {
      writeContract({
        abi: uniswapV3StakerAbi,
        address: staker,
        functionName: "burn",
        args: [account.address, BigInt(lp.id)],
      });

      // If success remove from staked and add to available
      if (isSuccess) {
        removeStakedLP(lp);
        addAvailableLP(lp);
      }
    }
  }

  return (
    <Card.Root rounded={"xl"} maxWidth="270px" mx={{ base: "auto", sm: "0" }}>
      <Card.Header>
        <Text textAlign="center" fontSize="xl" fontWeight="bold">
          {lp.id.toString()}
        </Text>
      </Card.Header>
      <Card.Body display="flex" justifyContent="center" alignItems="center">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="217.5px"
          height="375px"
        >
          {lp.tokenUri != undefined ? (
            <div
              style={{ transform: "scale(0.75)", transformOrigin: "center" }}
              dangerouslySetInnerHTML={{
                __html: atob(lp.tokenUri.split(",")[1]),
              }}
            />
          ) : (
            <Spinner />
          )}
        </Box>
      </Card.Body>
      <Card.Footer>
        <Button
          rounded={"xl"}
          bgColor="rgb(82, 130, 26)"
          color="white"
          width="calc(100% - 16px)"
          margin="8px"
          onClick={depositOrBurn}
        >
          {stake ? "Stake" : "Unstake"}
        </Button>
      </Card.Footer>
    </Card.Root>
  );
};

export default LPCard;
