import DepositCard from "../components/DepositCard";
import {
  Box,
  Tabs,
  Flex,
  VStack,
  Icon,
  PopoverRoot,
  Spacer,
} from "@chakra-ui/react";
import StatsCard from "../components/StatsCard";
import WithdrawCard from "../components/WithdrawCard";
import { FaQuestionCircle } from "react-icons/fa";
import {
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverTitle,
} from "../components/ui/popover";

const CacherPage = () => {
  return (
    <Flex justify="center" align="center">
      <Box maxWidth="1400px" mx="auto" px="6">
        <VStack gap={10} align="center">
          <StatsCard />
          <Tabs.Root
            defaultValue="deposit"
            bg="gray.950"
            rounded={"xl"}
            borderWidth={"1px"}
          >
            <Tabs.List>
              <Tabs.Trigger value="deposit" fontSize={"1em"}>
                Deposit
              </Tabs.Trigger>
              <Tabs.Trigger value="withdraw" fontSize={"1em"}>
                Withdraw
              </Tabs.Trigger>
              <Spacer />
              <PopoverRoot>
                <PopoverTrigger asChild>
                  <Icon alignSelf="center" mx={3} cursor="pointer">
                    <span>
                      <FaQuestionCircle />
                    </span>
                  </Icon>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverBody>
                    <VStack align="start">
                      <PopoverTitle>What is this ?</PopoverTitle>
                      <p>
                        The cacher will create a cache for 30 days to earn
                        points.
                      </p>
                      <p>
                        Once the 30 days has passed, during a 24 hour window,
                        you will be able to withdraw your PRIME.
                      </p>
                      <p>
                        A new cache will be created with the new deposits during
                        the lock time.
                      </p>
                      <p>
                        You may withdraw your PRIME at any time if there is
                        enough available balance (deposits made outside the lock
                        time).
                      </p>
                    </VStack>
                  </PopoverBody>
                </PopoverContent>
              </PopoverRoot>
            </Tabs.List>
            <Tabs.Content value="deposit" p={0}>
              <DepositCard />
            </Tabs.Content>
            <Tabs.Content value="withdraw" p={0}>
              <WithdrawCard />
            </Tabs.Content>
          </Tabs.Root>
        </VStack>
      </Box>
    </Flex>
  );
};

export default CacherPage;
