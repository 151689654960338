import { Box, Flex, HStack, Icon, Input, Text, VStack } from "@chakra-ui/react";
import { formatEther, parseEther } from "viem";

interface TokenNumberInputProps {
  value: bigint | undefined;
  onChange: (value: bigint) => void;
  data: any;
  ticker: string;
}

const TokenNumberInput: React.FC<TokenNumberInputProps> = ({
  value,
  onChange,
  data,
  ticker,
}) => {
  return (
    <VStack align={"start"} w="full">
      <Flex w="full" gap={4}>
        <Input
          fontSize={"1.5em"}
          variant="subtle"
          colorScheme={"whiteAlpha"}
          value={value !== undefined ? formatEther(value) : undefined}
          onChange={(e) => {
            onChange(parseEther(e.target.value));
          }}
          w="full"
        />
        <Box p="10px" bg="gray.800" rounded="xl">
          <HStack>
            <Icon color="rgb(222 241 65)" boxSize="20px">
              <svg
                width="24"
                height="24"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
              >
                <path
                  d="M7.664 2.979 1.122 9.494a6 6 0 1 1 10.063-6.516h-3.52Z"
                  fill="currentColor"
                ></path>
                <path
                  d="m8.315 4.582-6.098 6.075a6.126 6.126 0 0 1-.466-.422l6.315-6.294h3.571c.077.21.142.424.194.64H8.315ZM9.367 7.155h2.522a6.002 6.002 0 0 1-7.23 4.695l4.708-4.695Z"
                  fill="currentColor"
                ></path>
                <path
                  d="M12 6c0 .064 0 .128-.003.192H8.966l-5.34 5.32a5.897 5.897 0 0 1-.597-.297l5.687-5.67h3.267c.011.15.017.302.017.455Z"
                  fill="currentColor"
                ></path>
              </svg>
            </Icon>
            <Text
              whiteSpace={"normal"}
              fontSize={"1em"}
              fontWeight={"medium"}
              bgClip={"text"}
              color="white"
            >
              {ticker}
            </Text>
          </HStack>
        </Box>
      </Flex>
      <HStack>
        <Text>balance: {data ? formatEther(data.value) : "?"}</Text>
        <Text
          cursor={"pointer"}
          textShadow={"0px 0px 5px var(--chakra-colors-brand-500)"}
          onClick={() => {
            if (data) onChange(data.value);
          }}
        >
          MAX
        </Text>
      </HStack>
    </VStack>
  );
};

export default TokenNumberInput;
