import { getDefaultConfig } from "@rainbow-me/rainbowkit";
import { http } from "viem";
import { mainnet } from "viem/chains";

if (
  !process.env.REACT_APP_ALCHEMY_KEY ||
  !process.env.REACT_APP_RAIMBOW_PROJECT_ID ||
  !process.env.REACT_APP_RAIMBOW_APP_NAME
) {
  throw new Error("Cannot create wagmi config");
}

export const config = getDefaultConfig({
  appName: process.env.REACT_APP_RAIMBOW_APP_NAME,
  projectId: process.env.REACT_APP_RAIMBOW_PROJECT_ID,
  chains: [mainnet],
  ssr: false,
  transports: {
    [mainnet.id]: http(
      `https://eth-mainnet.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMY_KEY}`,
    ),
  },
});
