import {
  Box,
  Flex,
  Spacer,
  Link as ChakraLink,
  Text,
  Icon,
  HStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { Link } from "react-router";
import { useLocation } from "react-router-dom";
import "@fontsource/ramabhadra";

export const Header = () => {
  const location = useLocation();
  const showText = useBreakpointValue({ base: false, md: true });

  return (
    <Box
      as="header"
      borderBottom="1px solid"
      borderColor="gray.900"
      position="sticky"
      top="0"
      zIndex="1000"
      bg={"bg"}
      mb={"40px"}
    >
      <Box p={4} maxWidth="1400px" mx="auto">
        <Flex align="center">
          <Flex flexBasis={0} flexGrow={1} justifyContent={"start"}>
            <Link to={"/"}>
              <Flex align="center">
                <Icon color="brand.500" boxSize="40px">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M7.664 2.979 1.122 9.494a6 6 0 1 1 10.063-6.516h-3.52Z"
                      fill="currentColor"
                    ></path>
                    <path
                      d="m8.315 4.582-6.098 6.075a6.126 6.126 0 0 1-.466-.422l6.315-6.294h3.571c.077.21.142.424.194.64H8.315ZM9.367 7.155h2.522a6.002 6.002 0 0 1-7.23 4.695l4.708-4.695Z"
                      fill="currentColor"
                    ></path>
                    <path
                      d="M12 6c0 .064 0 .128-.003.192H8.966l-5.34 5.32a5.897 5.897 0 0 1-.597-.297l5.687-5.67h3.267c.011.15.017.302.017.455Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </Icon>
                <Text
                  ml={2}
                  fontFamily="Ramabhadra"
                  fontSize="xl"
                  lineHeight="40px"
                  display={showText ? "block" : "none"}
                >
                  Prime Maxi
                </Text>
              </Flex>
            </Link>
          </Flex>
          <Flex flexGrow={1} flexBasis={0} justifyContent={"center"}>
            <HStack gap={6}>
              <ChakraLink
                asChild
                color={location.pathname === "/cacher" ? "white" : "gray.400"}
                outline="none"
                _hover={{ color: "white", textDecoration: "none" }}
              >
                <Link to={"/cacher"}>Cacher</Link>
              </ChakraLink>
              <ChakraLink
                asChild
                color={location.pathname === "/staker" ? "white" : "gray.400"}
                outline="none"
                _hover={{ color: "white", textDecoration: "none" }}
              >
                <Link to={"/staker"}>Staker</Link>
              </ChakraLink>
            </HStack>
          </Flex>
          <Flex flexGrow={1} flexBasis={0} justifyContent={"end"}>
            <ConnectButton />
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};
