import { Box, Button, Card, Input, Text, VStack } from "@chakra-ui/react";
import TokenNumberInput from "./TokenNumberInput";
import { useEffect, useState } from "react";
import {
  useAccount,
  useBalance,
  useReadContract,
  useWriteContract,
} from "wagmi";
import { cacher, prime } from "../config/constants";
import { erc20Abi } from "viem";
import { primeCacherAbi } from "../config/abis/PrimeCacher";

const DepositCard = () => {
  const [value, setValue] = useState<bigint>(0n);

  const [disabled, setDisabled] = useState<boolean>(false);
  const [approve, setApprove] = useState<boolean>(false);
  const { writeContract, isSuccess } = useWriteContract();
  const account = useAccount();

  const { data } = useBalance({
    token: prime,
    address: account.address,
  });

  const result = useReadContract({
    abi: erc20Abi,
    address: prime,
    functionName: "allowance",
    args: account.address ? [account.address, cacher] : undefined,
  });

  const onDeposit = async () => {
    if (!account?.address) return;

    if (approve) {
      writeContract({
        abi: erc20Abi,
        address: prime,
        functionName: "approve",
        args: [cacher, value],
      });

      if (isSuccess) {
        await result.refetch();
      }
    } else {
      writeContract({
        abi: primeCacherAbi,
        address: cacher,
        functionName: "mint",
        args: [account.address, value],
      });

      if (isSuccess) {
        setValue(0n);
      }
    }
  };

  useEffect(() => {
    if (result.data !== undefined) {
      if (result.data === 0n || result.data < value) {
        setApprove(true);
      } else {
        setApprove(false);
      }
    }
  }, [result]);

  useEffect(() => {
    if (value === undefined || value === 0n || (data && value > data.value)) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [value, account.address]);

  return (
    <Card.Root
      rounded={"xl"}
      roundedTop={"0"}
      p="0"
      width={{ base: "100%", md: "600px" }}
      borderWidth="0"
    >
      <Card.Body>
        <VStack gap={5} align="start">
          <Text opacity={".7"}>
            Deposit your PRIME and let them get auto cached
          </Text>
          <TokenNumberInput
            value={value}
            onChange={setValue}
            data={data}
            ticker="PRIME"
          />

          <Button
            rounded={"xl"}
            bgColor="rgb(82, 130, 26)"
            color="white"
            w="100%"
            disabled={disabled}
            onClick={onDeposit}
          >
            {disabled ? "Deposit" : approve ? "Approve" : "Deposit"}
          </Button>
        </VStack>
      </Card.Body>
    </Card.Root>
  );
};

export default DepositCard;
