import { Card, Grid, GridItem, Spinner, Text, VStack } from "@chakra-ui/react";
import { useReadContract } from "wagmi";
import { cacher, prime } from "../config/constants";
import { formatEther } from "viem";
import { useEffect, useState } from "react";
import getTotalPricePerToken from "../utils/getTotalPricePerToken";
import formatNumber from "../utils/formatNumber";
import { primeCacherAbi } from "../config/abis/PrimeCacher";
import axios from "axios";

const StatsCard = () => {
  const [totalValueLocked, setTotalValueLocked] = useState<string>();
  const [totalPoints, setTotalPoints] = useState<number>();

  const result = useReadContract({
    abi: primeCacherAbi,
    address: cacher,
    functionName: "getTotalLocked",
  });

  const fetchTVL = async () => {
    if (!result || !result.data) return;

    const balance = Number(formatEther(result.data[0] + result.data[1]));
    const value = await getTotalPricePerToken(balance, prime);

    setTotalValueLocked(formatNumber(value.toFixed(0).toString()) + "$");
  };

  const fetchPoints = async () => {
    const apiUrl = `https://promptcachingdashboardserver.onrender.com/addresses`;
    try {
      const response = await axios.post(apiUrl, {
        addresses: [
          cacher
        ]
      });

      const score = response?.data?.total_score || 0;
      setTotalPoints(score);
    } catch (error) {
      setTotalPoints(0);
      console.error("Error fetching points", error);
    }
  };

  useEffect(() => {
    fetchPoints();
  }, []);

  useEffect(() => {
    fetchTVL();
  }, [result?.data]);

  return (
    <Card.Root rounded={"xl"}>
      <Card.Body>
        <Grid gap={"2em"} templateColumns={"repeat(3, 1fr)"}>
          <GridItem minW={"30%"}>
            <VStack>
              <Text
                whiteSpace={"normal"}
                fontSize={"1.125em"}
                opacity={".7"}
                textAlign={"center"}
              >
                {"Total Prime Locked"}
              </Text>
              {!result.data ? (
                <Spinner />
              ) : (
                <Text
                  whiteSpace={"normal"}
                  fontSize={"1.5em"}
                  fontWeight={"bold"}
                  bgClip={"text"}
                  color="white"
                >
                  {formatEther(result.data[0] + result.data[1])}
                </Text>
              )}
            </VStack>
          </GridItem>
          <GridItem minW={"30%"}>
            <VStack>
              <Text
                whiteSpace={"normal"}
                fontSize={"1.125em"}
                opacity={".7"}
                textAlign={"center"}
              >
                {"Total Value Locked"}
              </Text>
              {!totalValueLocked ? (
                <Spinner />
              ) : (
                <Text
                  whiteSpace={"normal"}
                  fontSize={"1.5em"}
                  fontWeight={"bold"}
                  bgClip={"text"}
                  color="white"
                >
                  {totalValueLocked}
                </Text>
              )}
            </VStack>
          </GridItem>
          <GridItem minW={"30%"}>
            <VStack>
              <Text
                whiteSpace={"normal"}
                fontSize={"1.125em"}
                opacity={".7"}
                textAlign={"center"}
              >
                {"Total Points Accrued"}
              </Text>
              {totalPoints === undefined ? (
                <Spinner />
              ) : (
                <Text
                  whiteSpace={"normal"}
                  fontSize={"1.5em"}
                  fontWeight={"bold"}
                  bgClip={"text"}
                  color="white"
                >
                  {totalPoints.toFixed(0)}
                </Text>
              )}
            </VStack>
          </GridItem>
        </Grid>
      </Card.Body>
    </Card.Root>
  );
};

export default StatsCard;
