import React from "react";
import logo from "./logo.svg";
import { Header } from "./components/header";
import { Provider } from "./components/ui/provider";

import "@rainbow-me/rainbowkit/styles.css";

import {
  darkTheme,
  lightTheme,
  RainbowKitProvider,
} from "@rainbow-me/rainbowkit";
import { WagmiProvider } from "wagmi";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { config } from "./config/wagmi";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
import StakerPage from "./pages/staker";
import CacherPage from "./pages/cacher";
import Footer from "./components/footer";
import { LpsProvider } from "./providers/LpsProvider";
import { Box } from "@chakra-ui/react";

const queryClient = new QueryClient();

const Router = () => (
  <Routes>
    <Route path="*" element={<Navigate to="/cacher" />} />
    <Route path={"/staker"} element={<StakerPage />} />
    <Route path={"/cacher"} element={<CacherPage />} />
  </Routes>
);

function App() {
  return (
    <Provider>
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <RainbowKitProvider
            theme={darkTheme({
              accentColor: "rgb(82, 130, 26)",
            })}
          >
            <LpsProvider>
              <HashRouter>
                <Box bgColor="#09090bd" display="flex" flexDirection="column" minHeight="100vh">
                  <Header />
                  <Box flex="1">
                    <Router />
                  </Box>
                  <Footer />
                </Box>
              </HashRouter>
            </LpsProvider>
          </RainbowKitProvider>
        </QueryClientProvider>
      </WagmiProvider>
    </Provider>
  );
}

export default App;
