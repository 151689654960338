import { Box, GridItem, Heading, SimpleGrid } from "@chakra-ui/react";
import LPCard from "../components/LPCard";
import { useLps } from "../providers/LpsProvider";
import { toaster, Toaster } from "../components/ui/toaster";
import { useEffect } from "react";

const StakerPage = () => {
  const { stakedLPs, availableLPs } = useLps();

  useEffect(() => {
    if (stakedLPs.length === 0 && availableLPs.length === 0) {
      toaster.create({
        title: "No PRIME/WETH LPs found",
        description: "Please check back later",
      });
    }
  }, [stakedLPs, availableLPs]);

  return (
    <Box maxWidth="1400px" mx="auto" px="6">
      <Toaster />
      <Heading mb="3">Staked LPs</Heading>
      <SimpleGrid mb="10" gap={4} minChildWidth="250px">
        {stakedLPs.map((lp, index) => (
          <GridItem key={index} colSpan={1}>
            <LPCard lp={lp} stake={false} />
          </GridItem>
        ))}
      </SimpleGrid>
      <Heading mb="3">Available LPs</Heading>
      <SimpleGrid gap={4} minChildWidth="250px">
        {availableLPs.map((lp, index) => (
          <GridItem key={index} colSpan={1}>
            <LPCard lp={lp} stake={true} />
          </GridItem>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default StakerPage;
