import {
  Box,
  Button,
  Card,
  Flex,
  HStack,
  Separator,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import TokenNumberInput from "./TokenNumberInput";
import { useEffect, useState } from "react";
import {
  useAccount,
  useBalance,
  useReadContracts,
  useWriteContract,
} from "wagmi";
import { cacher, prime } from "../config/constants";
import { primeCacherAbi } from "../config/abis/PrimeCacher";
import { ProgressCircleRing, ProgressCircleRoot } from "./ui/progress-circle";
import { erc20Abi, formatEther } from "viem";
import { convertSeconds } from "../utils/convertSeconds";

const WithdrawCard = () => {
  const [value, setValue] = useState<bigint>(0n);
  const [nextUnlockString, setNextUnlockString] = useState<string>("");
  const [nextUnlockPercentage, setNextUnlockPercentage] = useState<number>(100);
  const [availableBalance, setAvailableBalance] = useState<
    bigint | undefined
  >();
  const [timeLeft, setTimeLeft] = useState<number | undefined>();

  const [disabled, setDisabled] = useState<boolean>(false);
  const { writeContract, isSuccess } = useWriteContract();
  const account = useAccount();

  const { data: primeBalance } = useBalance({
    token: cacher,
    address: account.address,
  });

  const { data } = useReadContracts({
    contracts: [
      {
        abi: primeCacherAbi,
        address: cacher,
        functionName: "getTotalLocked",
      },
      {
        abi: primeCacherAbi,
        address: cacher,
        functionName: "surplus",
      },
      {
        abi: primeCacherAbi,
        address: cacher,
        functionName: "getNextUnlock",
      },
      {
        abi: erc20Abi,
        address: prime,
        functionName: "balanceOf",
        args: [cacher],
      },
    ],
  });
  const [totalLocked, surplus, nextUnlock, cacherBalance] = data || [];

  useEffect(() => {
    if (
      cacherBalance &&
      totalLocked &&
      surplus &&
      cacherBalance.result !== undefined &&
      totalLocked.result !== undefined &&
      surplus.result !== undefined
    ) {
      setAvailableBalance(
        BigInt(cacherBalance.result) -
          BigInt(totalLocked.result[1]) -
          BigInt(surplus.result),
      );
    }
  }, [cacherBalance, totalLocked, surplus]);

  useEffect(() => {
    setNextUnlockString(computeNextUnlock());
    setNextUnlockPercentage(computeNextUnlockPercentage());
  }, [timeLeft]);

  useEffect(() => {
    if (!nextUnlock || nextUnlock.result === undefined) return;
    if (nextUnlock.result === 0n) {
      setTimeLeft(0);
      return;
    }
    const now = Date.now() / 1000;
    const remaining = Number(nextUnlock.result) - now;
    setTimeLeft(remaining);

    const interval = setInterval(() => {
      setTimeLeft((prev) => (prev ? prev - 1 : 0));
    }, 1000);

    return () => clearInterval(interval);
  }, [nextUnlock]);

  const computeNextUnlock = (): string => {
    if (!timeLeft || timeLeft === 0) return "No lock";
    const { days, hours, minutes, seconds } = convertSeconds(timeLeft);

    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };

  const computeNextUnlockPercentage = (): number => {
    if (!timeLeft || timeLeft === 0) return 100;
    const totalLock = 3 * 7 * 24 * 60 * 60; // 3 weeks in seconds
    return ((totalLock - timeLeft) / totalLock) * 100;
  };

  const onWithdraw = async () => {
    if (!account?.address) return;

    writeContract({
      abi: primeCacherAbi,
      address: cacher,
      functionName: "burn",
      args: [account.address, value],
    });

    if (isSuccess) {
      setValue(0n);
    }
  };

  useEffect(() => {
    if (
      value === undefined ||
      value === 0n ||
      (primeBalance && value > primeBalance.value) ||
      (availableBalance !== undefined && value > availableBalance)
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [value, account.address, availableBalance]);

  return (
    <Card.Root
      rounded={"xl"}
      roundedTop={"0"}
      p="0"
      width={{ base: "100%", md: "600px" }}
      borderWidth="0"
    >
      <Card.Body>
        <VStack w="full" gap={5}>
          <Text opacity={".7"}>
            Withdraw your PRIME if enough balance is available or once the lock
            has expired
          </Text>
          <Flex gap={4} w="full">
            <Flex flexBasis={0} flexGrow={1} justifyContent={"start"}>
              <VStack align="start">
                <Text>Next unlock in</Text>
                <HStack>
                  <ProgressCircleRoot
                    value={nextUnlockPercentage}
                    size="xs"
                    colorPalette={
                      nextUnlockPercentage > 90 ? "green" : "yellow"
                    }
                  >
                    <ProgressCircleRing />
                  </ProgressCircleRoot>
                  <Text fontSize={"l"}>{nextUnlockString}</Text>
                </HStack>
              </VStack>
            </Flex>
            <Flex flexBasis={0} flexGrow={1} justifyContent={"center"}>
              <Separator orientation="vertical" height="14" />
            </Flex>
            <Flex flexBasis={0} flexGrow={1} justifyContent={"center"}>
              <Box>
                <Text>Available to withdraw</Text>
                {availableBalance === undefined ? (
                  <Spinner />
                ) : (
                  <Text fontSize={"xl"}>{formatEther(availableBalance)}</Text>
                )}
              </Box>
            </Flex>
          </Flex>
          <TokenNumberInput
            value={value}
            onChange={setValue}
            data={primeBalance}
            ticker={"cPRIME"}
          />
          <Button
            rounded={"xl"}
            bgColor="rgb(82, 130, 26)"
            color="white"
            w="100%"
            disabled={disabled}
            onClick={onWithdraw}
          >
            Withdraw
          </Button>
        </VStack>
      </Card.Body>
    </Card.Root>
  );
};

export default WithdrawCard;
